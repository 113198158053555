// var stickyHeader = false;
// var transformHomepageDonationAmountImages = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
var countUpStats = true;
var countUpStatsDuration = 500;

var subsiteHijackMainNav = ['say-their-name-day'];
var subsiteHijackMainLogoClickDestination = ['say-their-name-day'];
